import React, { useEffect, useState } from "react";
import "./Upload.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
// import { useLocation } from "react-router-dom";

function Upload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(20);
  const [code, setCode] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [allUploadedFiles, setAllUploadedFiles] = useState([]);

  useEffect(() => {
    const getCodes = async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_BASR_URL}/v1/files`,
        { id_token: localStorage.getItem('access_token') }
      );
      const data = res.data.data;
      const filteredData = [];
      data.map((file) => {
        const obj = {
          name: file.fileName,
          code: file.code,
        }
        filteredData.push(obj);
        return true;
      })
      setAllUploadedFiles(filteredData);

    };
    getCodes();
  }, [])


  const handleChange = (e) => {
    // console.log(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setUploaded(false);
    setUploadProgress(0);
    setCode(null);
  };

  const getSignedUrl = async () => {
    // const options = {
    //   headers: {
    //     'Authorization': localStorage.getItem('access_token')
    //   }
    // }

    const res = await axios.post(
      `${process.env.REACT_APP_BASR_URL}/v1/files/upload`,
      {
        fileName: selectedFile.name,
        id_token: localStorage.getItem('access_token')
      }
    );
    // console.log(res.data)
    return res.data.data;
  };

  const markUploaded = async (code) => {
    // const options = {
    //   headers: {
    //     'Authorization': localStorage.getItem('access_token')

    //   }
    // }
    const res = await axios.patch(
      `${process.env.REACT_APP_BASR_URL}/v1/files/upload/${code}`, {}
    );
    // console.log(res.data)
    return res.data.data;
  };

  const handleUpload = () => {
    if (!selectedFile) {
      // alert("Please choose a file");
      toast.error("Please choose a file", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "#5A4BDA",
          color: "white",
        },
      });
      return;
    }
    setUploading(true);
    getSignedUrl().then((signedData) => {
      const options = {
        headers: {
          "Content-Type": selectedFile.type,
          // "Accept":"*/*",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
          setUploadProgress(percent);
        },
      };
      // console.log(signedData);
      toast.promise(
        axios
          .put(signedData.uploadUrl, selectedFile, options)
          .then((res) => {
            // console.log(res);
            markUploaded(signedData.code).then((res) => {
              // console.log(res);
              if (res.success) {
                setCode(signedData.code);
                setUploaded(true);
                setUploading(false);
              }
            });
          })
          .catch((err) => {
            // console.log(err);
            setUploading(false);
            setCode(null);
            setUploaded(false);
          }),
        {
          loading: "Uploading...",
          success: "File Uploaded Successfully",
          error: "Error in uploading file",
        }
      );
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    toast.success("Code copied to clipboard", {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "#3FB422",
        color: "white",
      },
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    // console.log("drag over");
    if (!e.dataTransfer.items) {
      toast.error("Please choose a file", {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "#5A4BDA",
          color: "white",
        },
      });
    }
    const item = e.dataTransfer.items[0];
    if (item.kind === "file") {
      const entry = item.webkitGetAsEntry();
      if (entry && entry.isFile) {
        setSelectedFile(item.getAsFile());
        setUploaded(false);
        setUploadProgress(0);
        setCode(null);
        setUploading(false);
      } else {
        toast.error("Please choose a file", {
          duration: 2000,
          position: "top-center",
          style: {
            backgroundColor: "#5A4BDA",
            color: "white",
          },
        });
      }
    }
  };

  // const handleCopyClick = (text) => {
  //   navigator.clipboard.writeText(text)
  //     .then(() => {
  //       console.log('Text copied to clipboard:', text);
  //       // You can add additional feedback or UI update here if needed
  //     })
  //     .catch((error) => {
  //       console.error('Failed to copy text to clipboard:', error);
  //     });
  // };

  return (
    <div className="uploadContainer">
      <Toaster />

      <p className="title">Upload File</p>
      <label
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDragOver}
        className="inputLabel"
        htmlFor="file"
      >
        {selectedFile ? (
          <div className="dragSelectedBtnContainer">
            <p className="fileName">{selectedFile.name}</p>
            <img
              onClick={() => setSelectedFile(null)}
              style={{ height: "fit-content" }}
              src="./close.png"
              alt=""
              srcset=""
            />
            {/* <p className="fileName">{getFileSize(selectedFile.size)}</p> */}
          </div>
        ) : (
          <div className="dragBtnContainer">
            <img src="./upload.png" alt="" srcset="" />
            <p className="dragFile">Drag file to upload</p>
            <p className="dragFile">or</p>
            <p className="browseFileBtn" type="">
              Browse file
            </p>
          </div>
        )}
        <div className="dragBottom">
          <p className="maxFile">
            Max file size: <span className="maxFileType">50 MB</span>
          </p>
          <p className="maxFile">
            Supported file type:{" "}
            <span className="maxFileType">JPG, PDF, PNG, GIF</span>
          </p>
        </div>
      </label>
      <input onChange={handleChange} type="file" name="file" id="file" />
      <div className={`uploadBtnContainer ${uploading ? "show" : ""}`} style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          onClick={handleUpload}
          style={{
            width: uploading ? `${uploadProgress}%` : "",
            padding: uploading ? "10px 0px" : "",
          }}
          disabled={uploading || uploaded}
          className={`uploadBtn ${selectedFile ? "uploading" : ""} ${uploaded ? "uploaded" : ""
            }`}
          type="button"
        >
          {!uploading && !uploaded && "Upload"}
          {uploading && !uploaded && `${uploadProgress}%`}
          {!uploading && uploaded && (
            <span>
              Uploaded <img src="./tick.png" alt="" srcset="" />
            </span>
          )}
        </button>
      </div>
      {code && (
        <p className="copyPara">
          Your File Code{" "}
          <span onClick={handleCopy}>
            {code}
            <img src="./copy.svg" alt="" srcset="" />
          </span>
        </p>
      )}
      <p className="title" style={{ marginTop: "2rem" }}>Uploaded Files</p>
      <div style={{ margin: '1rem 0', maxHeight: '14rem', overflow: "auto", }}>
        {allUploadedFiles.map((file, index) => (
          <div key={index} style={{ fontSize: 20, display: 'flex', width: "20rem" }}>
            <div style={{ width: '80%', flexWrap: 'wrap' }}>
              {file.name.length > 16 ? file.name.slice(0, 16) + "..." : file.name}
            </div>
            <div style={{ color: '#5A4BDA', fontWeight: 800, width: '20%', display: 'flex', justifyContent: 'space-between' }}>
              <div>{file.code}</div>
            </div>
          </div>
        )
        )}
      </div>
    </div>
  );
}

export default Upload;
