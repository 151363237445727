import { useEffect } from 'react';
import Upload from './components/upload/Upload';
import Login from './components/login/login';
import { Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import './App.css';

function App() {

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      navigate('/login');
    }
  }, [])

  return (
    <Routes>
      <Route path="/" Component={Upload} />
      <Route path="/login" Component={Login} />
    </Routes>
  );
}

export default App;
