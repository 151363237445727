const { GoogleLogin } = require("@react-oauth/google");
const { useNavigate } = require("react-router-dom");



const Login = () => {

    const navigate = useNavigate();

    // const login = GoogleLogin({
    //     onSuccess: (res) => {
    //         console.log(res);
    //         console.log(res.code);
    //         localStorage.setItem('access_token', JSON.stringify(res.code));
    //         navigate('/');
    //     },
    // })


    return (
        <>
            <div style={{ alignItems: 'center', alignContent: 'center', display: 'flex', justifyContent: 'center', height: '100vh' }}>
                <GoogleLogin
                    onSuccess={res => {
                        localStorage.setItem('access_token', res.credential);
                        navigate('/');
                    }}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                    useOneTap
                />
            </div>
        </>
    )
}


export default Login;